import React from "react";

import ImageCarousel from "../UI/ImageCarousel";

import mainOveriewImg from "../../assets/images/15-Main_Overview_800x600.jpg";
import livingRoom1Img from "../../assets/images/4-Living_Room_1_800x600.jpg";
import livingRoom2Img from "../../assets/images/5-Living_Room_2_800x600.jpg";
import bathroomImg from "../../assets/images/6-Family_Batroom_800x600.jpg";
import twinBedImg from "../../assets/images/7-Twin_Room_800x600.jpg";
// import enSuiteImg from "../../assets/images/8-En-Suite.jpg";
// import wardrobeImg from "../../assets/images/9-Walk_in_Wardrobe.jpg";
import mainBed1Img from "../../assets/images/10-Main_Bedroom_1_800x600.jpg";
import mainBed2Img from "../../assets/images/11-Main_Bedroom_2_800x600.jpg";
import mainBed3Img from "../../assets/images/12-Main_Bedroom_3_800x600.jpg";
import kitchen1Img from "../../assets/images/13-Kitchen_Area_1_800x600.jpg";
import kitchen2Img from "../../assets/images/14-Kitchen_Area_2_800x600.jpg";
// import sideDeckImg from "../../assets/images/16-Side_Decking.jpg";
import frontDeckImg from "../../assets/images/17-Front_Decking_800x600.jpg";

const carouselSlides = [
  {
    id: 1,
    active: true,
    imgSrc: mainOveriewImg,
    label: "Living Room",
  },
  {
    id: 2,
    active: false,
    imgSrc: livingRoom1Img,
    label: "Living Room",
  },
  {
    id: 3,
    active: false,
    imgSrc: livingRoom2Img,
    label: "Living Room",
  },
  {
    id: 4,
    active: false,
    imgSrc: bathroomImg,
    label: "Family Bathroom",
  },
  {
    id: 5,
    active: false,
    imgSrc: twinBedImg,
    label: "Spare Bedroom",
  },
  {
    id: 6,
    active: false,
    imgSrc: mainBed1Img,
    label: "Master Bedroom",
  },
  {
    id: 7,
    active: false,
    imgSrc: mainBed2Img,
    label: "Master Bedroom",
  },
  {
    id: 8,
    active: false,
    imgSrc: mainBed3Img,
    label: "Master Bedroom",
  },
  // {
  //   id: 9,
  //   active: false,
  //   imgSrc: enSuiteImg,
  //   label: "En-Suite Batroom",
  // },
  // {
  //   id: 10,
  //   active: false,
  //   imgSrc: wardrobeImg,
  //   label: "Walk In Wardrobe",
  // },
  {
    id: 11,
    active: false,
    imgSrc: kitchen1Img,
    label: "Kitchen Area",
  },
  {
    id: 12,
    active: false,
    imgSrc: kitchen2Img,
    label: "Kitchen Area",
  },
  // {
  //   id: 13,
  //   active: false,
  //   imgSrc: sideDeckImg,
  //   label: "Side Decking",
  // },
  {
    id: 14,
    active: false,
    imgSrc: frontDeckImg,
    label: "Front Decking",
  },
];

const Accomodation = () => {
  return (
    <div className='container px-3'>
      <main>
        <h1>What Casa DavLex Has To Offer</h1>
        <section className='mb-4'>
          <ImageCarousel carouselSlides={carouselSlides} />
        </section>
        <section>
          <h1 className='display-5 fw-bold'>
            🚐 Welcome to Casa DavLex: Your Perfect Caravan Retreat! 🏖️
          </h1>
          <p>
            Step inside our charming static caravan, Casa DavLex, and immerse
            yourself in a world of comfort and seaside bliss. With thoughtfully
            designed amenities, this coastal haven promises an unforgettable
            experience for you and your loved ones.
          </p>
          <h4 className='display-8'>
            🛏️ Comfortable Bedrooms for Dreamy Nights 🌙
          </h4>
          <p>
            Casa DavLex boasts two inviting bedrooms to ensure a restful night's
            sleep after your exciting adventures. The master bedroom features a
            luxurious double bed, complemented by a spacious walk-in wardrobe
            that provides ample storage for your belongings. Indulge in the
            convenience of your very own en-suite bathroom with a modern shower,
            offering privacy and convenience during your stay.
          </p>
          <h4 className='display-8'>🛋️ Cozy Twin Bedroom for All Guests 🌟</h4>
          <p>
            The second bedroom offers a cozy and welcoming atmosphere with two
            twin beds, perfect for accommodating additional guests or the little
            ones. After a day of beach fun, the twin bedroom provides a peaceful
            retreat where you can recharge and get ready for new escapades.
          </p>
          <h4 className='display-8'>
            🍽️ Modern Kitchen for Culinary Delights 🍳
          </h4>
          <p>
            At Casa DavLex, we understand the importance of a well-equipped
            kitchen for delightful dining experiences. Our kitchen features a
            microwave, dishwasher, and washing machine, providing the ultimate
            convenience for your stay. Whether you're preparing a quick snack or
            cooking a family feast, you'll find everything you need at your
            fingertips. The full-size fridge freezer ensures you can stock up on
            your favorite treats and keep drinks cool for those warm beach days.
          </p>
          <h4 className='display-8'>
            📺 Entertainment and Relaxation in the Living Area 🎬🎲
          </h4>
          <p>
            The living area is designed to be your go-to spot for relaxation and
            entertainment. Unwind on the plush sofas and enjoy quality time
            together with the DVD player and a selection of DVDs, perfect for
            cozy movie nights. And for those seeking a bit of friendly
            competition, our collection of games guarantees hours of laughter
            and fun.
          </p>
          <h4 className='display-8'>🛋️ Sofa Bed for Extra Space 🛌</h4>
          <p>
            For added convenience, the sofa in the living room can be
            transformed into a comfortable sofa bed, providing extra sleeping
            space for additional guests. Whether it's for a spontaneous
            sleepover or to accommodate a larger group, the sofa bed ensures
            that everyone has a cozy spot to rest after a day of seaside
            adventures.
          </p>
          <h4 className='display-8'>
            🍹 Private Deck with Furniture and a Gas BBQ 🍔🌅
          </h4>
          <p>
            Step outside onto your private deck, complete with comfortable
            furniture that invites you to lounge and admire the stunning sunset
            views over the South East coast. The deck also boasts a convenient
            Gas BBQ, perfect for sizzling up a delicious meal while soaking in
            the fresh sea breeze. Whether it's a family barbecue or a romantic
            evening under the stars, this outdoor space is a delightful addition
            to your seaside escape.
          </p>
          <h4 className='display-8'>⛱️ Your Seaside Getaway Awaits! 🏄‍♂️</h4>
          <p>
            With its two bedrooms, shower-only bathroom, well-equipped kitchen,
            and entertainment options, Casa DavLex offers everything you need
            for a delightful beachside retreat. Secure your coastal getaway now
            by contacting us at{" "}
            <a href='mailto:info@casadevlex.co.uk'>Casa DavLex</a>, and get
            ready to experience the magic of the South East coast of England.
          </p>
          <h4 className='display-8'>
            🐬 Let Casa DavLex Be Your Beachside Home! 🌞
          </h4>
          <p>
            From the sun-kissed days to the enchanting sunsets, Casa DavLex
            promises a memorable escape where the allure of the ocean meets the
            comfort of your very own coastal sanctuary. Come and create lasting
            memories in this beachside gem – your Casa by the sea!
          </p>
        </section>
      </main>
    </div>
  );
};

export default Accomodation;
