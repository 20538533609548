import { useState, useEffect, useCallback } from "react";
import moment from "moment/moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "moment-timezone";

import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  useGetForMonthMutation,
  useUpdateEventMutation,
} from "../../slices/bookingsApiSlice";
import Loader from "../UI/Loader";

import styles from "./Dasboard.module.css";
import { Dropdown } from "react-bootstrap";
import EditBookingModal from "./EditBookingModal";

moment.tz.setDefault("Europe/London");
const localizer = momentLocalizer(moment);

// const getDatesBetween = (startDate, endDate) => {
//   const dates = [];
//   let currentDate = new Date(startDate);

//   while (currentDate <= endDate) {
//     dates.push(new Date(currentDate));
//     currentDate.setDate(currentDate.getDate() + 1);
//   }

//   return dates;
// };

const convertEvents = res =>
  res.map(e => ({
    start: moment(e.startDate, "YYYYMMDDHHNN"),
    end: moment(e.endDate, "YYYYMMDDHHNN"),
    title:
      `${e.booked ? "Booked " : "Available "}` +
      `${e.bookingRef && e.booked ? "(" + e.bookingRef + ")" : ""}`,
    rawBooking: e,
  }));

const Dashboard = () => {
  const [events, setEvents] = useState([]);
  // const [bookings, setBookings] = useState([]);
  const [startDate, setStartDate] = useState(moment().add(-1, "month"));
  const [endDate, setEndDate] = useState(moment().add(1, "month"));
  const [bookingState, setBookingState] = useState("All");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEditModal, setShowEditModel] = useState(false);

  const [getForMonth, { isLoading }] = useGetForMonthMutation();
  const [updateEvent] = useUpdateEventMutation();

  const retrieveEvents = useCallback(async () => {
    const res = await getForMonth({
      startYear: moment(startDate).year(),
      startMonth: moment(startDate).month() + 1,
      endYear: moment(endDate).year(),
      endMonth: moment(endDate).month() + 1,
      bookingState,
    }).unwrap();
    return res;
  }, [bookingState, startDate, endDate, getForMonth]);

  useEffect(() => {
    retrieveEvents().then(res => {
      // setBookings(res);
      const monthEvents = convertEvents(res);
      setEvents(monthEvents);
    });
  }, [startDate, endDate, getForMonth, bookingState, retrieveEvents]);

  const hanldeRangeChanged = ({ start, end }) => {
    setStartDate(moment(start));
    setEndDate(moment(end));
  };

  const customEventPropGetter = event => {
    if (event.title.includes("Available")) {
      return {
        className: styles.eventAvailable,
      };
    }
    return {
      className: styles.eventBooked,
    };
  };

  const drillDownHanlder = () => {
    console.log("drilldown");
  };

  const handleEventSelected = event => {
    setSelectedEvent(event);
    setShowEditModel(true);
  };

  const handleStateDropdown = state => {
    setBookingState(state);
  };

  const handleEditModalClose = async rawEvent => {
    setShowEditModel(false);
    setSelectedEvent(null);
    if (rawEvent) {
      await updateEvent(rawEvent);
      const newEvents = await retrieveEvents();
      const monthEvents = convertEvents(newEvents);
      setEvents(monthEvents);
    }
  };

  return (
    <div>
      {showEditModal && (
        <EditBookingModal
          handleModalClose={handleEditModalClose}
          showModal={showEditModal}
          editEvent={selectedEvent.rawBooking}
        />
      )}
      {isLoading && <Loader />}
      <Dropdown className='mb-3'>
        <Dropdown.Toggle variant='success' id='dropdown-basic'>
          {bookingState}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleStateDropdown("Booked")}>
            Booked Only
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStateDropdown("Available")}>
            Available Only
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStateDropdown("All")}>
            All Slots
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView='month'
        style={{ height: "75vh" }}
        events={events}
        selectable={false}
        onRangeChange={hanldeRangeChanged}
        views={["month"]}
        onDrillDown={drillDownHanlder}
        eventPropGetter={customEventPropGetter}
        onSelectEvent={handleEventSelected}
      />
    </div>
  );
};

export default Dashboard;
