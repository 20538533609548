import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  FaSignInAlt,
  FaSignOutAlt,
  FaHome,
  FaCaravan,
  FaMapMarkedAlt,
  FaUmbrellaBeach,
  FaTachometerAlt,
} from "react-icons/fa";

import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";

const Header = () => {
  const { loggedIn } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutHandler = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <header>
      <Navbar bg='light' expand='lg' collapseOnSelect>
        <Container>
          <Navbar.Brand href='/'>Casa Davlex</Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <LinkContainer to='/'>
                <Nav.Link>
                  <FaHome /> Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/contact'>
                <Nav.Link>
                  <FaMapMarkedAlt /> Contact Us
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/accomodation'>
                <Nav.Link>
                  <FaCaravan /> Accomodation
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/local-attractions'>
                <Nav.Link>
                  <FaUmbrellaBeach /> Local Attractions
                </Nav.Link>
              </LinkContainer>
              {loggedIn ? (
                <>
                  <LinkContainer to='/dashboard'>
                    <Nav.Link>
                      <FaTachometerAlt /> Dashboard
                    </Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={logOutHandler}>
                    <FaSignOutAlt />
                    Logout
                  </Nav.Link>
                </>
              ) : (
                <LinkContainer to='/owner-login'>
                  <Nav.Link>
                    <FaSignInAlt /> Owner Login
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
