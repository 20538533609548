import { apiSlice } from "./apiSlice";
const API_URL = "/api";

export const ownersApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: data => ({
        url: `${API_URL}/identity`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = ownersApiSlice;
