import { Link } from "react-router-dom";

import ImageCarousel from "../UI/ImageCarousel";
import DescriptionSection from "./DescriptionSection";

import seawallImg from "../../assets/images/1-Seawall To Hythe_800x600.jpg";
import outsideFrontImg from "../../assets/images/19_Outside_From_Font_800x600.jpg";
import mainOveriewImg from "../../assets/images/15-Main_Overview_800x600.jpg";
import neptuneImg from "../../assets/images/2-Neptune From Seawall._800x600jpg.jpg";
import Reviews from "../Reviews/Reviews";

const carouselSlides = [
  {
    id: 1,
    active: false,
    imgSrc: seawallImg,
    label: "View from the Sea Wall",
  },
  {
    id: 2,
    active: true,
    imgSrc: outsideFrontImg,
    label: "Our Van",
  },
  {
    id: 3,
    active: false,
    imgSrc: mainOveriewImg,
    label: "Living Room",
  },
  {
    id: 4,
    active: false,
    imgSrc: neptuneImg,
    label: "Neptune Pub and Carvery",
  },
];
const HomePage = () => {
  return (
    <div className='px-3'>
      <main>
        <section className='mb-3'>
          <div>
            <ImageCarousel carouselSlides={carouselSlides} />
          </div>
        </section>
        <div className='d-none d-md-block float-end mr-3 w-25 p-2 border broder-dark'>
          <Reviews />
        </div>
        <div className='d-md-none border broder-dark'>
          <Reviews />
        </div>
        <div>
          <section>
            <DescriptionSection />
          </section>
          <section>
            <h2>What We Offer</h2>
            <ul>
              {/* List the amenities your caravan offers */}
              <li>Sleeps 6</li>
              <li>Two Bedrooms</li>
              <li>Private En-Suite Bathroom</li>
              <li>Flat Screen TV</li>
              <li>Washing Machine and Dishwasher</li>
              <p>
                <Link to='/accomodation'>Click Form Full Description</Link>
              </p>
            </ul>
          </section>
          <section>
            <h2>What To See</h2>
            <p>
              Click <Link to='/local-attractions'>Here</Link> To Explore The
              Local Area
            </p>
          </section>
          <section>
            <h2>Pricing and Availability</h2>
            <p>
              {/* Mention pricing details and availability information */}
              For pricing and availability, please contact us at{" "}
              <a href='mailto:info@casadavlex.co.uk'>Casa DavLex</a>.
            </p>
            {/* <Link to="/pricing">View Pricing</Link> */}
          </section>
          <section>
            <h2>Contact Us</h2>
            <p>
              {/* Encourage visitors to get in touch for inquiries or bookings */}
              Have questions or ready to book? Reach out to us today!
            </p>
            <Link to='/contact'>Contact Us</Link>
          </section>
        </div>
      </main>
      <footer>
        {/* Footer content */}
        {/* Include any relevant information, links, or copyright notice */}
      </footer>
    </div>
  );
};

export default HomePage;
