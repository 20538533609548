import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormContainer from "../UI/FormContainer";
import { useLoginMutation } from "../../slices/ownersApiSlice";
import { setCredentials } from "../../slices/authSlice";
import Loader from "../UI/Loader";

const OwnerLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispath = useDispatch();

  const [login, { isLoading }] = useLoginMutation();
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  const submitHandler = async e => {
    e.preventDefault();
    try {
      const res = await login({
        username,
        password,
      }).unwrap();
      dispath(setCredentials(res.token));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <FormContainer>
      <h1>Sign In</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group className='my-2' controlId='username'>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Username'
            value={username}
            onChange={e => setUsername(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className='my-2' controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        {isLoading ? (
          <Loader />
        ) : (
          <Button type='submit' variant='primary' className='mt-3'>
            Sign In
          </Button>
        )}
      </Form>
    </FormContainer>
  );
};

export default OwnerLogin;
