import React from "react";

import Map from "../Map/Map";

const Contact = () => {
  return (
    <div className='px-3'>
      <Map />
    </div>
  );
};

export default Contact;
