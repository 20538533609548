import React from "react";

import classes from "./ImageCarousel.module.css";
// import "./ImageCarousel.css";

const ImageCarousel = ({ carouselSlides }) => {
  return (
    <div
      id='carousel'
      className={`${classes.carousel} h-25 carousel slide`}
      data-bs-ride='carousel'
    >
      <div className={`carousel-indicators ${classes["car-indicators"]}`}>
        {carouselSlides.map((slide, index) => (
          <button
            key={index}
            type='button'
            data-bs-target='#carousel'
            data-bs-slide-to={`${index}`}
            className={`dummy ${slide.active ? "active" : ""}`}
            aria-current={slide.active}
            aria-label={slide.label}
          ></button>
        ))}
      </div>
      <div className={`carousel-inner`}>
        {carouselSlides.map(slide => (
          <div
            className={`carousel-item ${classes["car-item"]} ${
              slide.active ? "active" : ""
            }`}
            key={slide.id}
          >
            <img src={slide.imgSrc} className='d-block w-100' alt='...' />
            <div
              className={`carousel-caption ${classes["car-caption"]} d-none d-md-block`}
            >
              <h5>{slide.label}</h5>
            </div>
          </div>
        ))}
      </div>
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carousel'
        data-bs-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carousel'
        data-bs-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  );
};

export default ImageCarousel;
