import React from "react";

import { GoogleMap, Marker } from "@react-google-maps/api";

import "./Map.css";

const Map = () => {
  return (
    <GoogleMap
      zoom={15}
      center={{
        lat: 51.044001,
        lng: 1.024187,
      }}
      mapContainerClassName='map-container'
    >
      <Marker
        position={{
          lat: 51.044001,
          lng: 1.024187,
        }}
      >
        Casa DavLex
      </Marker>
    </GoogleMap>
  );
};

export default Map;
