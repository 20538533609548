import React from "react";

const Review = ({ review }) => {
  return (
    <li className='list-group-item'>
      "{review.review}" - <i>{review.submittedBy}</i>
    </li>
  );
};

export default Review;
