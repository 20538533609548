import { apiSlice } from "./apiSlice";
const API_URL = "/api";

export const bookingsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getForMonth: builder.mutation({
      query: data => ({
        url: `${API_URL}/bookings/${data.startYear}/${data.startMonth}/${data.endYear}/${data.endMonth}?state=${data.bookingState}`,
        method: "GET",
      }),
    }),
    updateEvent: builder.mutation({
      query: data => ({
        url: `${API_URL}/bookingsadmin/${data.startDate}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PUT",
        body: data,
      }),
    }),
  }),
});

export const { useGetForMonthMutation, useUpdateEventMutation } =
  bookingsApiSlice;
