import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditBookingModal = ({ showModal, handleModalClose, editEvent }) => {
  const [bookingRef, setBookingRef] = useState(editEvent?.bookingRef);
  const [booked, setBooked] = useState(editEvent?.booked);

  const onSave = () => {
    const newEditEvent = {
      ...editEvent,
      bookingRef,
      booked,
    };
    handleModalClose(newEditEvent);
  };
  return (
    <>
      {editEvent && (
        <Modal show={showModal} onHide={() => handleModalClose(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3'>
                <Form.Label>Checkin Date</Form.Label>
                <Form.Control
                  placeholder='Checkin Date'
                  disabled
                  type='text'
                  defaultValue={editEvent.startDate}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Checkout Date</Form.Label>
                <Form.Control
                  placeholder='Checkout Date'
                  disabled
                  type='text'
                  defaultValue={editEvent.endDate}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Booking Ref</Form.Label>
                <Form.Control
                  placeholder='Booking Ref'
                  type='text'
                  value={bookingRef}
                  onChange={e => setBookingRef(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Check
                  label='Booked?'
                  checked={booked}
                  onChange={e => setBooked(e.target.checked)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => handleModalClose(null)}>
              Close
            </Button>
            <Button variant='primary' onClick={onSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EditBookingModal;
