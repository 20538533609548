import React from "react";

const LocalAttractions = () => {
  return (
    <div className='px-3'>
      <main>
        <section>
          <h4 className='display-8'>
            🏰 Explore the Charming Town of Hythe and the Romney, Hythe and
            Dymchurch Railway (RH&DR) 🚂
          </h4>
          <p>
            Welcome to Hythe, a charming town steeped in history and surrounded
            by natural beauty. Start your journey with a leisurely stroll along
            the historic Hythe Canal, where you can take a relaxing boat ride
            and admire the picturesque surroundings. History enthusiasts will
            delight in a visit to Hythe's historic landmarks, such as the Hythe
            Castle, a magnificent structure dating back centuries, and the
            fascinating St. Leonard's Church with its ancient crypt. Don't
            forget to visit the enchanting Brockhill Country Park, offering
            serene woodland walks and stunning lake views. Experience the best
            of Hythe's local produce and crafts at the Hythe Farmers' Market,
            held monthly in the town center.
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🚂 Step Back in Time with the Romney, Hythe and Dymchurch Railway
            (RH&DR) 🌳
          </h4>
          <p>
            For a truly unique and nostalgic experience, hop aboard the Romney,
            Hythe and Dymchurch Railway (RH&DR), one of the world's smallest
            public railways. This delightful narrow-gauge steam railway takes
            you on a scenic journey through the picturesque Romney Marsh and
            offers captivating views of the English Channel. As you chug along
            the tracks, you'll be transported back in time to an era of bygone
            charm and timeless beauty. The RH&DR is a favorite attraction for
            visitors of all ages, and it's an experience that will leave you
            with cherished memories of your time in Hythe.
          </p>
        </section>
        <section>
          <h4 className='display-8'>🌊 Discover the Enchanting Dymchurch 🎢</h4>
          <p>
            Dymchurch is a quintessential seaside resort filled with delightful
            attractions for visitors of all ages. Embark on an adventure at
            Dymchurch Amusement Park, home to vintage fairground attractions and
            thrilling rides that will delight the whole family. Stroll along
            Dymchurch's vibrant promenade, lined with quaint shops, cafes, and
            amusements, or spend a leisurely day on the sandy beach, building
            sandcastles and soaking up the sun. For nature lovers, Dymchurch
            boasts the picturesque Dymchurch Redoubt, a 19th-century fort turned
            museum, offering fascinating insights into the region's military
            history. Don't miss a visit to the Dymchurch Martello Tower, another
            historical gem with captivating views of the English Channel.
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🐾 Wildlife Adventures at Port Lympne Safari Park 🦓
          </h4>
          <p>
            Just a short drive from CasaDavlex, discover the incredible world of
            wildlife at Port Lympne Safari Park. This award-winning wildlife
            reserve is home to a vast array of animals, from majestic lions and
            graceful giraffes to curious meerkats and playful lemurs. Set
            against the breathtaking backdrop of the Kent countryside, Port
            Lympne offers unforgettable safari experiences, where you can embark
            on a thrilling safari ride and get up close to some of the world's
            most magnificent creatures. Immerse yourself in the beauty of nature
            as you explore the park's expansive grounds, carefully designed to
            provide a safe and enriching environment for the animals. A visit to
            Port Lympne Safari Park is a truly unforgettable adventure for
            wildlife enthusiasts of all ages.
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🏄‍♂️ Enjoy Water Adventures at The Hythe Watersports Centre 🌊
          </h4>
          <p>
            For the thrill-seekers and water enthusiasts, The Hythe Watersports
            Centre is a must-visit attraction. Located on the scenic Royal
            Military Canal, the center offers a wide range of exciting
            water-based activities, including kayaking, paddleboarding, and
            canoeing. Whether you're a seasoned watersports enthusiast or a
            beginner eager to try something new, the Hythe Watersports Centre
            has something for everyone
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🍴 Indulge in Culinary Delights at The Neptune Pub and Carvery 🍽️
          </h4>
          <p>
            After a day of exploring, satisfy your taste buds at The Neptune Pub
            and Carvery. This beloved local pub offers a mouthwatering carvery,
            featuring freshly carved meats and an array of delectable sides.
            Whether you're in the mood for a hearty lunch or a delightful
            dinner, the pub's warm atmosphere and scrumptious food are sure to
            leave a lasting impression.
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🏖️ Discover the Coastal Charm of Folkestone 🏄‍♂️
          </h4>
          <p>
            A short distance from Hythe lies the coastal gem of Folkestone.
            Known for its beautiful beaches, Folkestone offers a perfect blend
            of natural beauty and vibrant seaside attractions. Enjoy a leisurely
            walk along The Leas, a scenic clifftop promenade with breathtaking
            views of the English Channel. Explore the charming Lower Leas
            Coastal Park, with its well-kept gardens and recreational areas,
            perfect for picnics and family outings. Art enthusiasts will
            appreciate the Creative Quarter, an area filled with art galleries,
            studios, and independent shops, showcasing the town's thriving arts
            and culture scene. For history buffs, a visit to the historic
            Folkestone Harbour Arm, once a bustling Victorian pier, now
            transformed into a lively destination with a variety of eateries and
            entertainment options, is a must.
          </p>
        </section>
        <section>
          <h4 className='display-8'>🚢 Take a Journey Across the Channel 🛳️</h4>
          <p>
            Our strategic location close to the English Channel makes it an
            excellent starting point for exciting adventures across the water.
            You can embark on a short journey to Calais, France, using the
            Eurotunnel Le Shuttle or the Channel Tunnel. The experience of
            traveling through the iconic tunnel beneath the Channel is not only
            efficient but also a unique way to explore the European continent.
          </p>
        </section>
        <section>
          <h4 className='display-8'>
            🍽️ Savour Delicious Seafood and Local Cuisine 🦞🍴
          </h4>
          <p>
            Folkestone is renowned for its delicious seafood and a thriving food
            scene. Head to the quaint harbor area to sample freshly caught fish
            and seafood delicacies in charming waterfront restaurants. Indulge
            in a traditional fish and chips meal or treat yourself to culinary
            delights in one of the town's many eateries, showcasing a diverse
            range of international cuisines.
          </p>
        </section>
        {/* <section>
          <h4 className='display-8'></h4>
          <p></p>
        </section>
        <section>
          <h4 className='display-8'></h4>
          <p></p>
        </section> */}
      </main>
    </div>
  );
};

export default LocalAttractions;
