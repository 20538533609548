import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import HomePage from "./components/HomePage/HomePage";
import Accomodation from "./components/AccomodationPage/Accomodation";
import Contact from "./components/ContactPage/Contact";
import LocalAttractions from "./components/LocalAttractionsPage/LocalAttractions";
import OwnerLogin from "./components/OwnerArea/OwnerLogin";
import Dashboard from "./components/OwnerArea/Dashboard";
import PrivateRoute from "./components/OwnerArea/PrivateRoute";
// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomePage />} />
      <Route path='/accomodation' element={<Accomodation />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/local-attractions' element={<LocalAttractions />} />
      <Route path='/owner-login' element={<OwnerLogin />} />
      {/* Private routes */}
      <Route path='' element={<PrivateRoute />}>
        <Route path='/dashboard' element={<Dashboard />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
