import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { loggedIn } = useSelector(state => state.auth);

  return loggedIn ? <Outlet /> : <Navigate to='/owner-login' replace />;
};

export default PrivateRoute;
