// import neptuneImg from "../../assets/images/2-Neptune From Seawall._800x600jpg.jpg";

const DescriptionSection = () => {
  return (
    <section>
      <h1 className='display-5 fw-bold'>
        🌊 Welcome to Casa DavLex: Your Seaside Oasis at New Beach Holiday Park!
        🏖️
      </h1>
      <p>
        Nestled between the idyllic town of Hythe and the charming seaside
        resort of Dymchurch, Casa DavLex invites you to experience the ultimate
        coastal adventure on the South East coast of England. Situated within
        the picturesque New Beach Holiday Park, our charming static caravan
        offers the perfect blend of beachside living and exciting amenities.
      </p>
      <h4 className='display-8'>🏠 Your Coastal Retreat by the Sea 🐚</h4>
      <p>
        Step into Casa DavLex, your very own coastal sanctuary where relaxation
        and bliss meet. With the gentle sea breeze and the melodic waves as your
        backdrop, this inviting static caravan promises a rejuvenating escape
        from the hustle and bustle of everyday life.
      </p>
      <h4 className='display-8'>
        🌅 Endless Explorations: Hythe & Dymchurch 🏰🎢
      </h4>
      <p>
        Discover the nearby delights of Hythe and Dymchurch, each offering their
        unique charms. Explore the enchanting town of Hythe, where historic
        treasures await around every corner. From the iconic Hythe Canal to the
        fascinating Hythe Castle, there's no shortage of wonders to uncover.
      </p>
      <p>
        Embrace the vibrant spirit of Dymchurch, with its classic seaside
        appeal. The resort boasts a lively promenade, vintage fairground
        attractions at the Dymchurch Amusement Park, and the opportunity to
        relax on its golden sandy beach, surrounded by the beauty of the coast.
      </p>
      <h4 className='display-8'>
        🏊‍♂️ New Beach Holiday Park: Extra Fun Awaits! 🎉
      </h4>
      <p>
        While New Beach Holiday Park offers fantastic amenities like a fully
        supervised indoor swimming pool and a lively club house with amusement
        arcade, please note that these amenities come with a small surcharge.
        The additional fee ensures that you get to enjoy the facilities at your
        convenience during your stay at Casa DavLex.
      </p>
      <h4 className='display-8'>
        🍻 Indulge in Local Delights at "The Neptune Pub and Carvery" 🍽️
      </h4>
      {/* <img src={neptuneImg} style={{width:"100px"}} className="float-end mr-3" alt="Neptune"/> */}
      <p>
        Located within easy reach of Casa DavLex, "The Neptune Pub and Carvery"
        is a must-visit destination for delectable dining and a warm, welcoming
        atmosphere. This beloved local pub is renowned for its mouthwatering
        carvery, where you can savor a sumptuous array of freshly carved meats
        and an assortment of delectable sides. Whether you're seeking a hearty
        lunch or a satisfying dinner after a day of seaside adventures, "The
        Neptune Pub and Carvery" promises a delightful culinary experience that
        will leave you satisfied and eager to return.
      </p>
      <h4 className='display-8'>🍺 Enjoy a Relaxing Pint by the Sea 🌊</h4>
      <p>
        With its charming location near the coast, "The Neptune Pub and Carvery"
        also offers a perfect setting to unwind with a refreshing pint of local
        ale or your favorite beverage. Whether you choose to sit indoors and
        soak in the friendly ambiance or prefer to bask in the sun on the
        outdoor terrace, the pub's inviting atmosphere makes it a fantastic spot
        to enjoy a relaxing drink and create cherished memories with family and
        friends.
      </p>
      <p>
        For more information and to explore their menu options, please visit{" "}
        <a
          href='http://www.neptunecarvery.com/'
          target='_blank'
          rel='noreferrer'
        >
          "The Neptune Pub and Carvery"
        </a>
      </p>
      <h4 className='display-8'>⛵ Your Seaside Getaway Awaits! 🏄‍♂️</h4>
      <p>
        Are you ready to experience the magic of beachside living with the added
        convenience of New Beach Holiday Park's amenities? Secure your coastal
        retreat at Casa DavLex now and immerse yourself in the captivating
        beauty of the South East coast of England. Contact us at{" "}
        <a href='mailto:info@casadavlex.co.uk'>Casa DavLex</a> to reserve your
        slice of paradise by the sea.
      </p>
      <h4 className='display-8'>
        🐬 Let Casa DavLex Be Your Beachside Home! 🌞
      </h4>
      <p>
        From the sun-kissed days to the enchanting sunsets, Casa DavLex promises
        a memorable escape where the allure of the ocean meets the comfort of
        your very own coastal sanctuary. Come and create lasting memories in
        this beachside gem – your Casa by the sea!
      </p>
    </section>
  );
};

export default DescriptionSection;
