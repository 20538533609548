import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_LODGE_API_URL,
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["Owner"],
  endpoints: builder => ({}),
});
