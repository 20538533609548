import React, { useEffect, useState } from "react";
import Review from "./Review";

const apiUrl = process.env.REACT_APP_LODGE_API_URL;

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      var resp = await fetch(`${apiUrl}/api/reviews`);
      var data = await resp.json();
      setReviews(data);
    };
    fetchReviews();
  }, []);
  return (
    <>
      {" "}
      {reviews.length > 0 ? (
        <div>
          <h3 className='display-8'>What Our Guests are Saying</h3>
          <ul className='list-group p-3'>
            {reviews.map(rev => (
              <Review key={rev.id} review={rev} />
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <h3 className='display-8'>Loading reviews....</h3>
        </div>
      )}
    </>
  );
};

export default Reviews;
